import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import get from "lodash.get";

import MovieCarousel from "../../../../components/MovieCarousel";
import MovieHero from "../../../../components/MovieHero";
import ViewNext from "../../../../components/ViewNext";
import MovieList from "../../../../components/MovieList";
import Container from "../../../../styles/Container";
import HelmetInfo from "../../../../components/HelmetInfo";

const Line = styled.div`
  width: 100%;
  border-top: 7px solid rgba(155, 155, 155, 0.218835);
`;

const Catalogue = ({ data }) => {
  let movies = undefined;
  if (Array.isArray(data.allPrismicMovie.edges)) {
    movies = data.allPrismicMovie.edges.map((movie) => {
      return { document: movie.node };
    });
  }

  return (
    <>
      <HelmetInfo page_data={data.prismicInternationalTitles.data} title_override={"International Titles - Catalogue"} />
      <MovieCarousel items={data.prismicInternationalTitles.data.carousel} />
      <MovieHero heading="Catalogue" />
      <Container>
        <Line />
      </Container>
      <MovieList
        heading="Catalogue"
        items={movies}
        hasSearch
        totalCount={data.allPrismicMovie.totalCount}
      />
      <ViewNext
        text="View Next Page"
        link={`/distribution/screen-media/us-syndication`}
        linkText="US Syndication"
        borderTop
        whiteBackground
      />
    </>
  );
};

export default Catalogue;

export const query = graphql`
  query CatalogueQuery {
    prismicInternationalTitles {
      data {
        carousel {
          image {
            url
            alt
          }
          button_text
          button_link {
            link_type
            uid
            type
            url
          }
          image_heading
        }
      }
    }
    allPrismicMovie(sort: { fields: data___title }) {
      totalCount
      edges {
        node {
          ... on PrismicMovie {
            type
            uid
            data {
              movie_poster {
                url
                alt
              }
              title
              info
              genre
            }
          }
        }
      }
    }
  }
`;
